import React, { useState } from 'react';
import CustomTextField from "./shared/CustomTextField";
import moment from "moment";
import ConstantStyles from "../styles/ConstantStyles.json"
const ChatFooter = ({ socket }) => {
  const [message, setMessage] = useState("")
  const handleTyping = () => socket.emit("typing", `${localStorage.getItem("userName")} is typing`)

  const handleSendMessage = (e) => {
    e.preventDefault()
    if (message.trim() && localStorage.getItem("userName")) {
      //send message / scoket io
      socket.emit("message",
        {
          text: message,
          datetime: moment().format('LTS'),
          name: localStorage.getItem("userName"),
          id: `${socket.id}${Math.random()}`,
          socketID: socket.id
        }
      )
    }
    setMessage("")
  }
  return (
    <div className='chat__footer'>
      <form className='form' onSubmit={handleSendMessage}>
        <CustomTextField
          value={message}
          onChange={setMessage}
          className='message'
          multiline={true}
          minRows={2}
          type="text"
          id="email"
          customSX={{
            padding: "4px 15px",
            // marginBottom: "10px"
          }}
        />
        <button
          style={{
            width: "auto",
            // height: "100%",
            color: ConstantStyles.white,
            border: ConstantStyles['default-border']
          }}
          className="sendBtn">SEND</button>
      </form>
    </div>
  )
}

export default ChatFooter