import React, { useEffect, useState, useRef } from 'react'
import ChatBar from './ChatBar'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'

const ChatPage = ({ socket }) => {
  const [messages, setMessages] = useState([])
  const [typingStatus, setTypingStatus] = useState("")
  const [userName, setUsername] = useState("")
  const lastMessageRef = useRef(null);
  let randomString = Math.random().toString(36).substr(2, 5)
  // let randomString = "Username"

  const startChat = () => {

    if (userName === "") {
      localStorage.setItem("userName", randomString)
      // alert(randomString)
      socket.emit("newUser", { randomString, socketID: socket.id })
      setUsername(randomString)
    }

  }

  // useEffect(() => {
  //   if (userName === "") {
  //     localStorage.setItem("userName", randomString)
  //     // alert(randomString)
  //     socket.emit("newUser", { randomString, socketID: socket.id })
  //     setUsername(randomString)
  //   }
  // }, [])


  //pag fetch ?
  useEffect(() => {
    socket.on("messageResponse", data => setMessages([...messages, data]))
    // console.log(messages)
    // socket.on("message",function(e){
    //   setMessages([...messages, e])
    //   console.log(e);
    // })
  }, [socket, messages])

  useEffect(() => {
    socket.on("typingResponse", data => setTypingStatus(data))
  }, [socket])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="chat">
      <ChatBar socket={socket} />
      <div className='chat__main'>
        <p onClick={startChat}>Start</p>
        <ChatBody socket={socket} messages={messages} socketID={socket.id} user_id={localStorage.getItem("userName")} typingStatus={typingStatus} lastMessageRef={lastMessageRef} />
        <ChatFooter socket={socket} />
      </div>
    </div>
  )
}

export default ChatPage