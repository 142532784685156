import React from 'react'
import { useNavigate } from "react-router-dom"

const ChatBody = ({ messages, typingStatus, lastMessageRef, user_id, socketID, socket }) => {
  const navigate = useNavigate()


  const handleLeaveChat = () => {
    localStorage.removeItem("userName")
    // socket.emit("disconnect", { randomString: user_id, socketID: socketID })
    // navigate("/")
    // window.location.reload()
  }

  return (
    <>
      <header className='chat__mainHeader'>
        <p>Hello <b>{user_id}!</b></p>
        <button className='leaveChat__btn' onClick={handleLeaveChat}>LEAVE CHAT</button>
      </header>


      <div className='message__container'>
        {messages.map(message => (
          message.name === localStorage.getItem("userName") ? (
            <div className="message__chats" key={message.id}>
              <p className='sender__name'>You </p>
              <div className='message__sender'>
                <p>{message.text}</p>
                <p style={{ textAlign: "right", fontSize: "10px" }}>{message.datetime}</p>
              </div>
            </div>
          ) : (
            <div className="message__chats" key={message.id}>
              <p>{message.name}</p>
              <div className='message__recipient'>
                <p>{message.text}</p>
                <p style={{ textAlign: "right", fontSize: "10px" }}>{message.datetime}</p>
              </div>
            </div>
          )
        ))}

        <div className='message__status'>
          <p>{typingStatus}</p>
        </div>
        <div ref={lastMessageRef} />
      </div>
    </>
  )
}

export default ChatBody